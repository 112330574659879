import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@cseemploye/environments/environment';
import { BASEURL } from '@mcv/config';
import { AuthResult, CseEmploye, SingleResult } from '@mcv/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

const TOKEN = 'cseemploye_id_token' + environment.client_env;
const EXPIREAT = 'cseemploye_expires_at' + environment.client_env;
const SUPERADMIN = 'cseemploye_superadmin' + environment.client_env;

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    user$: BehaviorSubject<CseEmploye> = new BehaviorSubject<CseEmploye>(null);
    model = 'cse-employe';
    superAdmin = false;

    constructor(private httpClient: HttpClient, @Inject(BASEURL) protected baseUrl: string) {
        this.superAdmin = !!window.localStorage.getItem(SUPERADMIN);
    }

    private _user: CseEmploye;

    get user(): CseEmploye {
        return this._user;
    }

    set user(user: CseEmploye) {
        this._user = user;
        this.user$.next(user);
    }

    checkIp(): Observable<SingleResult<{ mustCaptcha: boolean }>> {
        return this.httpClient.get<SingleResult<{ mustCaptcha: boolean }>>(`${this.baseUrl}/${this.model}/App/checkIp`);
    }

    // tslint:disable-next-line:variable-name
    login(formData: any): Observable<any> {
        return this.httpClient
            .post<SingleResult<AuthResult>>(`${this.baseUrl}/${this.model}/login`, formData)
            .pipe(
                map(r => r.data),
                tap(r => this.setSession(r))
            );
    }

    resetPassword(formData: any): Observable<any> {
        return this.httpClient
            .post<any>(`${this.baseUrl}/${this.model}/resetpassword`, formData);
    }

    checkPasswordCode(formData: any): Observable<any> {
        return this.httpClient
            .post<any>(`${this.baseUrl}/${this.model}/checkPasswordCode`, formData);
    }

    confirmPasswordReset(formData: any): Observable<any> {
        return this.httpClient
            .put<any>(`${this.baseUrl}/${this.model}/confirmPasswordReset`, formData);
    }

    setSession(authResult: AuthResult): void {
        const expiresAt = moment()
            .add(authResult.expiresIn, 'second');
        if (authResult.superAdmin) {
            localStorage.setItem(SUPERADMIN, '1');
            this.superAdmin = true;
        }
        localStorage.setItem(TOKEN, authResult.token);
        localStorage.setItem(EXPIREAT, JSON.stringify(expiresAt.valueOf()));
    }

    logout(): void {
        localStorage.removeItem(SUPERADMIN);
        localStorage.removeItem(TOKEN);
        localStorage.removeItem(EXPIREAT);
        this.clearData();
    }

    clearData() {
        this.superAdmin = false;
        this.user = null;
    }

    isLoggedIn(): boolean {
        return localStorage.getItem(EXPIREAT) && moment()
            .isBefore(this.getExpiration());
    }

    isLoggedOut(): boolean {
        return !this.isLoggedIn();
    }

    getExpiration(): any {
        const expiration = localStorage.getItem(EXPIREAT);
        const expiresAt = JSON.parse(expiration);
        return moment(expiresAt);
    }

    getToken(): string {
        return localStorage.getItem(TOKEN);
    }

    logAs(token: string): Observable<AuthResult> {
        return this.httpClient.post<AuthResult>(`${this.baseUrl}/${this.model}/logas`, { token });
    }
}
